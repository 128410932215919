<template>
  <div>
    <v-dialog v-model="dialog.flag" width="500px">
      <v-card>
        <DivLoader
          v-if="dialog.loading"
          :loading_type="'article, list-item-two-line, list-item-two-line, actions'"
        >
        </DivLoader>
        <div v-else>
          <v-form @submit.prevent="saveSlot()">
            <v-card-title class="pl-7">
              <span class="headline">{{ $lang.EDIT_SLOT }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col class="py-4" cols="12" md="6" sm="4">
                    <v-menu
                      v-model="date_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      min-width="290px"
                      offset-y
                      transition="scale-transition"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dialog.data.apt_date"
                          v-on="on"
                          :label="$lang.SLOT_DATE"
                          :placeholder="$lang.SLOT_DATE"
                          class="filter"
                          dense
                          readonly
                        >
                          <template slot="append"> </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dialog.data.apt_date"
                        :min="today"
                      >
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="date_menu = false"
                          >Cancel</v-btn
                        >
                        <v-btn color="primary" text @click="formateDisplayDate"
                          >OK</v-btn
                        >
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col class="py-1" cols="12" md="6" sm="4">
                    <v-select
                      height="30"
                      v-model="dialog.data.apt_slot_type"
                      :label="$lang.SLOT"
                      :items="$keys.SLOT_RANGE"
                    ></v-select>
                  </v-col>
                </v-row> </v-container
            ></v-card-text>
            <v-card-actions class="pb-5 px-5">
              <v-spacer></v-spacer>
              <v-btn
                class="px-5 py-4"
                color="secondary_2"
                outlined
                rounded
                @click="dialog.flag = false"
              >
                <b> {{ $lang.CANCEL }}</b>
              </v-btn>
              <v-btn
                class="px-7 py-5"
                color="secondary_2"
                rounded
                type="submit"
                @click="saveSlot"
              >
                <b style="color: #ffff"> {{ $lang.SAVE }}</b>
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card></v-dialog
    >
  </div>
</template>

<script>
export default {
  name: "TimeSlotEdit",
  props: ["dialog"],
  data() {
    return { date_menu: false, today: new Date().toISOString().substr(0, 10) };
  },
  components: {
    DivLoader: () => import("@/components/ui/DivLoader"),
  },
  methods: {
    /* function to format the date */
    formateDisplayDate() {
      this.display_date = this.display_date_formate(this.dialog.data.apt_date);
      this.date_menu = false;
    },
    saveSlot() {
      const form = {
        slot_id: this.dialog.data.id,
        apt_date: this.dialog.data.apt_date,
        apt_slot_type: this.dialog.data.apt_slot_type,
      };
      const successHandler = (response) => {
        console.log(response.data);
        if (response.data.success) {
          this.showSnakeBar("success", "Successfully updated");
          this.$emit("edit-slot");
          this.dialog.flag = false;
        }
      };
      const finallyHandler = () => {
        this.dialog.flag = false;
      };
      this.request_POST(
        this,
        this.$urls.UPDATE_VIDEO_VERIFICATION_SLOT,
        form,
        successHandler,
        null,
        null,
        finallyHandler
      );
    },
  },

  mounted() {
    console.log(this.dialog.data, "DATE");
  },
};
</script>

<style scoped>
</style>